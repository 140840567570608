@import url("https://use.typekit.net/rmh7msa.css");


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: nimbus-sans, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1em;
  color: #0026F5;
  cursor: crosshair !important;
  src: local('Helvetica');
  font-display: swap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.splash {
  background: #0026F5;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0px !important;
  right: 0px !important;
  position: fixed !important;
  top: 0px !important;
  bottom: 0px !important;
  z-index: 101;
  overflow-x: hidden !important;
  overflow-y: hidden !important
}

.splash-logo {
  display: inline-block;
  width: 100%;
  max-width: 175px;
}

.App {
  position: relative;
}

.canvas-class {
  position: absolute;
  top: 0px !important;
  left: 0px !important;
  z-index: 0 !important;
  background: transparent !important;
  background-image: none !important;
  box-shadow: none !important;
  height: 100% !important; 
}

h1 {
  font-size: 2.7em;
  font-weight: 300;
}

h2 {
  font-size: 4rem;
  font-weight: 400;
}

.grid-header {
  line-height: normal;
  font-size: 4rem;
  font-weight: 400;
}

h3 {
  font-weight: 300;
  font-size: 1.7em;
}

h3, h4, h5, span.rotate-text {
  font-size: 1.7em;
  font-weight: 300;
}

span.rotate-text, h3.rotate-text, h4.rotate-text {
  margin-right: -5px;
  line-height: 41px;
}

h1, h2, h3, h4, h5 {
  z-index: 10;
}

p {
  font-size: 1.2em;
  color: #0026F5;
}

::selection {
  background: rgba(0, 0, 255, 0.9);
  color: #000000;
}

.rotate-text, .rotate-text-ani {
  line-height: normal;
  transform: rotate(90deg);
  min-width: 528px;
}

h3.rotate-text {
  font-size: 1.7em;
}

.grid-area h3.rotate-text {
  font-size: 1.4em;
}

a, a:visited {
  color: #0026F5;
  text-decoration: none;
  border-bottom: 1px solid #0026F5;
  transition: 0.25s;
  z-index: 13 !important;
}

a:hover {
  color: gray;
  cursor: pointer;
  cursor: pointer !important;
}

a.cyan-logo {
  text-decoration: none;
  border-bottom: none;
}

.main-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1em 0em;
}

.logo-container {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 1em 0em;
}

.nav {
  width: 100%;
  max-width: 940px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0.25em 0em;
  font-size: 1.2em;
}

.nav a {
  margin-left: 2em;
}

section {
  margin: 1em 0em;
}

.content-container {
  width: 100%;
  max-width: 940px;
  display: flex;
  flex-direction: column;
  cursor: auto !important;
}

.notification {
  position: absolute;
  top: 5%;
  left: 5%;
  background: #e7e7e7;
  color: #000000;
  text-transform: uppercase;
  font-size: 0.8em;
  letter-spacing: 0.2em;
}

.cyan-logo {
  display: block;
  width: 100%;
  max-width: 125px;
  margin: 1em auto;
  z-index: 1 !important;
  position: relative;
}

.nav-button {
  background:rgba(0, 0, 255, 0.9);
  position: fixed;
  top: 0px !important;
  right: 0px !important;
}

.grid-area {
  width: 100%;
  height: 533px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-repeat: no-repeat !important;
  background-color: transparent;
  background-size: cover !important;
  position: relative;
  cursor: auto !important;
}

.grid-child {
  width: 74%;
  background: transparent;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-child:first-of-type {
  position: relative;
}

.grid-area::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background: url(./lib/images/cyan-line.svg) no-repeat !important;
  background-size: contain !important;
  background-position: center;
}

.grid-child:last-of-type {
  width: 22.3%;
  background: transparent;
}

.features {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  background: url(./lib/images/lines.svg) no-repeat !important;
  background-size: contain !important;
  background-position: center;
  z-index: 10 !important;
}

.feature-area {
  display: flex;
  width: 49.842%;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  border: none;
  min-height: 425px;
  background: transparent;
}

.feature-area-border {
  border: 1px solid #0026F5;
  margin-top: -1px;
}

.list-item {
  font-size: 1.4em;
  display: block; 
  width: 100%;
  text-transform: lowercase;
  z-index: 11;
}

.marquee-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent;
  height: 70px;
  overflow: hidden;
  margin: 3.5em 0em 0em;
  position: relative;
}

.marquee-text {
  font-weight: 300;
  width: 200%;
  word-wrap: unset;
  white-space: pre;
  margin: 0em;
  height: 30px;
  position: absolute;
  overflow: hidden;
  animation: marquee 25s linear infinite;
}

.contact-grid {
  height: 750px;
  width: 100%;
  background: url(./lib/images/email-grid.svg) no-repeat;
  background-size: auto;
  background-position: center;
  position: relative;
}

a.email-text, a.email-text:visited {
  display: inline-block;
  padding: 0.7em 0.85em;
  width: auto;
  background:#0026F5; 
  position: absolute;
  color: #FFF;
  font-size: 2em;
  font-weight: 300;
  transition: 0.25s;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a.email-text:hover {
  transform: rotate(25deg);
  color: #FFF;
}

.rates {
  display: inline-block;
  background: transparent;
  color: #333333;
  position: absolute;
  left: 50%;
  right: 50%;
  transform: (-0%, -0%);
  color:#0026F5 !important; 
  display: none;
}

a.email-text:last-of-type:hover {
  transform: rotate(-25deg);
}

.email-text:first-of-type {
  left: 13%;
  top: 33.4%;
}

.email-text:nth-of-type(2) {
  right: 8.6%;
  top: 60.65%;
}

.email-text:nth-of-type(3) {
  left: 0%;
  top: 77%;
}

.footer {
  background: #FFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 225px;
  z-index: 10 !important;
}

.footer-text {
  font-size: 2em;
  font-weight: 300;
  text-align: center;
}

/*
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
Animation
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
*/

@keyframes marquee {
  0% { left: 0; }
  100% { left: -100%; }
}

@keyframes blueGray {
  0% {color: #0026F5;}
  50% {color: lightgray;}
  100% {color: #0026F5;}
}

@keyframes border {
  0% {border-color: #0026F5;}
  50% {border-color: lightgray;}
  100% {color: #0026F5;}
}

@keyframes fadeOut {
    0% { opacity: 100;}
    50% { opacity: 100;}
    75% {opacity: 100;}
    100% {opacity: 0; display: none !important;}
}

@keyframes textColorRange {
    from { color: #0000FF;}
    to { color: #00FFFF;}
}

/*
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
Responsive Media Queries
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
*/

@media only screen and ( max-width: 980px ) {

  .rates {
      display: none;
  }

  .grid-area {
      flex-direction: column;
  }
  .nav {
      width: 90%;
      max-width: unset;
  }
  .cyan-logo {
    max-width: 150px;
  }
  .content-container {
      max-width: unset;
      width: 100%;
  }
  .grid-child:last-of-type {
      width: auto;
  }
  .rotate-text {
      transform: rotate(0deg);
      min-width: unset;
  }
  .features {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: url(./lib/images/lines.svg) repeat !important;
      background-size: contain !important;
      background-position: center;
  }
  .feature-area {
      display: flex;
      width: 49.842%;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-direction: column;
      min-height: 100vw;
      width: 85vw;
      animation: blue 5s linear infinite;
  }
  .grid-area {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #FFF;
      background-image: none !important;
      background-size: auto !important;
      cursor: auto !important;
  }
  .grid-area::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      background: url(./lib/images/lines.svg) repeat !important;
      background-size: contain !important;
      background-position: center;
  }
  .grid-child {
      width: auto;
      background: transparent;
      height: unset;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .marquee-container {
      margin: 3.5em 0em 3.15em;
  }
  .feature-area:nth-of-type(2) {
      order: 1;
      height: auto;
      min-height: unset;
  }
  .feature-area:first-of-type {
      order: 2;
  }
  .feature-area:nth-of-type(3) {
      order: 3;
      height: auto;
      min-height: unset;
  }
  .feature-area:nth-of-type(4) {
      order: 4;
  }
  .feature-area:nth-of-type(5) {
      order: 6;
  }
  .feature-area:nth-of-type(6) {
      order: 5;
      height: auto;
      min-height: unset;
  }
  .rates {
      background: transparent;
      left: 50%;
      right: 4%;
  }
  .contact-grid {
      height: 825px;
      width: 100%;
      background: url(./lib/images/email-grid.svg) repeat;
      background-size: cover;
      background-position: center;
      position: relative;
  }
  .email-text:first-of-type {
      left: 8%;
      top: 12%;
  }
  .email-text:nth-of-type(2) {
      right: 8.6%;
      top: 43.75%;
  }
  .email-text:nth-of-type(3) {
      left: 8.25%;
      top: 75%;
  }

  h3.rotate-text, h4.rotate-text {
      line-height: 46.5px;
  }

  .grid-child:first-of-type {
      position: relative;
      background: url(./lib/images/email-grid.svg) repeat !important;
      background-size: cover !important;
      cursor: auto !important;
      height: 875px;
      width: 100%;
  }

  .grid-child:last-of-type {
      background-size: cover;
      margin-top: 125px;
      margin-bottom: 75px;
      height: 350px;
  }

  .grid-child h2 {
    margin-top: 0.6em;
    font-size: 4em;
    line-height: normal;
  }

  .grid-child:last-of-type h3, .grid-child:last-of-type h4 {
    font-size: 1.7em;
    line-height: normal;
    max-width: 85vw;
  }

  h3.rotate-text {
      font-size: 1.7em;
  }

  .grid-child .grid-header {
    margin-top: 49px;
    line-height: normal;
    font-size: 4em;
    font-weight: 300;
  }

  .grid-area h3.rotate-text {
    font-size: 1.7em;
  }

  .nav a {
    margin-left: 2em;
    margin-bottom: 1em;
    font-size: 1em;
  }

}